import { Loading } from 'components/layout/Loading';
import { CustomRoute, CustomRouteProps } from 'components/routing/CustomRoute';
import { CustomSwitch } from 'components/routing/CustomSwitch';
import { useMySelector } from 'hooks/useMySelector';
import { ReactNode, Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { AuthState } from 'store/modules/auth/types';

import {
  LoginPage,
  TwoFactorAuthenticationPage,
  MultipleFactorAuthenticationPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  UsersPage,
  ProfilePage,
  RegisterPasswordPage,
  BusinessSegmentationPage,
  VulnerabilitiesPage,
  VulnerabilityVisualizationPage,
  VulnerabilityRegistrationPage,
  VulnerabilityEditingPage,
  RiskFactorPage,
  RiskFactorVisualizationPage,
  RiskFactorRegistrationPage,
  RiskFactorEditingPage,
  DamagePage,
  DamageVisualizationPage,
  DamageRegistrationPage,
  DamageEditingPage,
  PageNotFound404Page,
  CustomersPage,
  CustomerDetails,
  ThreatsPage,
  ThreatsVizualization,
  ChecklistsPage,
  ChecklistRegistrationPage,
  ChecklistVisualizationPage,
  ChecklistEditingPage,
  ThreatStep2Registration,
  ThreatsEdition,
  ControlTypesPage,
  RiskStep4Registration,
  RiskPage,
  RiskDamageRegistration,
  RiskVizualization,
  RiskEdition,
  ControlsPage,
  ControlDetail,
  Step5ControlAddition,
  ActivitiesPage,
  CrownJewelsPage,
  ControlEdition,
  QuestionsPage,
  QuizPage,
  QuizRegistrationPage,
  QuizAndQuestionsBonds,
  QuizVisualizationPage,
  QuizReview,
  QuestionVisualizationPage,
  QuestionRegistrationPage,
  CustomerProject,
  CustomerProjectStep2Registration,
  CustomerProjectDetail,
  ProjectSettingsPage,
  ProjectSettingsStep2Registration,
  ProjectSettingsVisualization,
  ProjectSettingsEdit,
  RiskEditionVinculation,
  QuestionnaireAnalystAnswer,
  CustomerProjectQuestionnaireVisualizationPage,
  QuestionnaireAnswerEditRegistrations,
  QuestionnaireAddQuestionnaire,
  SendActivityStep2Registrations,
  ChecklistActivityDetail,
  ChecklistQuestionDetail,
  ChecklistAddition,
  AnswerRegistrations,
  CustomerProjectRiskVisualization,
  CustomerProjectRiskEditionRegistrations,
  RecommendationCustomerProjectEdition,
  CustomerProjectThreatVisualization,
  CustomerProjectControlVisualization,
  ControlRecommendationsVisualization,
  QuestionnaireEditAnswer,
  FormQuestionnaireNIST,
  MarketAveragePage,
  MarketAverageDetails,
  FrameworksPage,
  StandardsPage,
  FrameworksVisualizationPage,
  StandardsVisualizationPage,
  FrameworkCSFCoreStandardsPage,
  FrameworkCSFCoreControlsPage,
  ProjectsForAnonymizationPage,
  AnonymizationDetailsPage,
  ApplicationLogsPage,
  ApplicationLogsDetailsPage,
  SolutionsPage,
  SolutionControlsLinkPage,
  ControlSolutionsVisualizationPage,
  FrameworkCSFCoreItensPage,
  UserProfilesPage,
  UserProfileDetailPage,
  GroupsPage,
  GroupDetailPage,
} from './lazyLoadingRoutes';

export function Routes() {
  const {
    user: { isAuthenticated, isVerified, totpDevice, is_customer },
  } = useMySelector<AuthState>('auth');

  const renderRouteAfterLogin = (
    pIsAuthenticated: boolean,
    pIsVerified: boolean | null,
    pIsCustomer: boolean | null
  ): ReactNode => {
    if (pIsAuthenticated && pIsVerified && !pIsCustomer)
      return <Redirect to='/customer-project' />;
    if (pIsAuthenticated && pIsVerified && pIsCustomer)
      return <Redirect to='/customer-project-detail' />;
    return null;
  };

  const routesData: CustomRouteProps[] = [
    {
      title: 'Login',
      path: '/',
      exact: true,
      component: LoginPage,
      isPrivateRoute: false,
      children: renderRouteAfterLogin(isAuthenticated, isVerified, is_customer),
    },
    {
      title: 'Autenticação de 2 Fatores',
      path: '/two-factor-authentication',
      exact: true,
      component: TwoFactorAuthenticationPage,
      isPrivateRoute: true,
      children:
        isAuthenticated && !totpDevice ? (
          <Redirect to='/multiple-factor-authentication' />
        ) : null,
    },
    {
      title: 'Autenticação de Múltiplos Fatores',
      path: '/multiple-factor-authentication',
      exact: true,
      component: MultipleFactorAuthenticationPage,
      isPrivateRoute: true,
      children:
        isAuthenticated && totpDevice ? (
          <Redirect to='/two-factor-authentication' />
        ) : null,
    },
    {
      title: 'Redefinição de senha',
      path: '/reset-password/:uuid/:token',
      exact: true,
      component: ResetPasswordPage,
      isPrivateRoute: false,
    },
    {
      title: 'Esqueci minha senha',
      path: '/forgot-password',
      exact: true,
      component: ForgotPasswordPage,
      isPrivateRoute: false,
    },
    {
      title: 'Dashboard',
      path: '/users',
      exact: true,
      component: UsersPage,
      isPrivateRoute: true,
    },
    {
      title: 'Profile',
      path: '/profile',
      exact: true,
      component: ProfilePage,
      isPrivateRoute: true,
    },
    {
      title: 'Cadastro de senha',
      path: '/register-password/:uuid/:token',
      exact: true,
      component: RegisterPasswordPage,
      isPrivateRoute: false,
    },
    {
      title: 'Clientes',
      path: '/customers',
      exact: true,
      component: CustomersPage,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risks',
      exact: true,
      component: RiskPage,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-edition/:id',
      exact: true,
      component: RiskEdition,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-edition-vinculation/:id',
      exact: true,
      component: RiskEditionVinculation,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-damage-registration/:id',
      exact: true,
      component: RiskDamageRegistration,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-registration/:id',
      exact: true,
      component: RiskStep4Registration,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-registration/:id/edit',
      exact: true,
      component: RiskStep4Registration,
      isPrivateRoute: true,
    },
    {
      title: 'Risco',
      path: '/risk-visualization/:id',
      exact: true,
      component: RiskVizualization,
      isPrivateRoute: true,
    },
    {
      title: 'Detalhe do Cliente',
      path: '/customer-details/:id/',
      exact: true,
      component: CustomerDetails,
      isPrivateRoute: true,
    },
    {
      title: 'Ameaça',
      path: '/threat',
      exact: true,
      component: ThreatsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Ameaça',
      path: '/threat-registration/:id/',
      exact: true,
      component: ThreatStep2Registration,
      isPrivateRoute: true,
    },
    {
      title: 'Ameaça',
      path: '/threat-vizualization/:id/',
      exact: true,
      component: ThreatsVizualization,
      isPrivateRoute: true,
    },
    {
      title: 'Ameaça',
      path: '/threat-edition/:id/',
      exact: true,
      component: ThreatsEdition,
      isPrivateRoute: true,
    },
    {
      title: 'Segmentação de Negócios',
      path: '/business-segmentation',
      exact: true,
      component: BusinessSegmentationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Vulnerabilidades',
      path: '/vulnerabilities',
      exact: true,
      component: VulnerabilitiesPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização da Vulnerabilidade',
      path: '/vulnerability-visualization/:id',
      exact: true,
      component: VulnerabilityVisualizationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Registro da Vulnerabilidade',
      path: '/vulnerability-registration/:id',
      exact: true,
      component: VulnerabilityRegistrationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Edição da Vulnerabilidade',
      path: '/vulnerability-editing/:id',
      exact: true,
      component: VulnerabilityEditingPage,
      isPrivateRoute: true,
    },
    {
      title: 'Fator de Risco',
      path: '/risk-factor',
      exact: true,
      component: RiskFactorPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização da Vulnerabilidade',
      path: '/risk-factor-visualization/:id',
      exact: true,
      component: RiskFactorVisualizationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Registro da Vulnerabilidade',
      path: '/risk-factor-registration/:id',
      exact: true,
      component: RiskFactorRegistrationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Edição da Vulnerabilidade',
      path: '/risk-factor-editing/:id',
      exact: true,
      component: RiskFactorEditingPage,
      isPrivateRoute: true,
    },
    {
      title: 'Controles',
      path: '/controls',
      exact: true,
      component: ControlsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização de Controle',
      path: '/control-visualization/:id',
      exact: true,
      component: ControlDetail,
      isPrivateRoute: true,
    },
    {
      title: 'Registro de Controle',
      path: '/control-registration/:id',
      exact: true,
      component: Step5ControlAddition,
      isPrivateRoute: true,
    },
    {
      title: 'Edição de Controle',
      path: '/control-edition/:id',
      exact: true,
      component: ControlEdition,
      isPrivateRoute: true,
    },
    {
      title: 'Dano',
      path: '/damages',
      exact: true,
      component: DamagePage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização do Dano',
      path: '/damage-visualization/:id',
      exact: true,
      component: DamageVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Registro do Dano',
      path: '/damage-registration/:id',
      exact: true,
      component: DamageRegistrationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Edição do Dano',
      path: '/damage-editing/:id',
      exact: true,
      component: DamageEditingPage,
      isPrivateRoute: false,
    },
    {
      title: 'Checklists',
      path: '/checklists',
      exact: true,
      component: ChecklistsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Registro de Checklist',
      path: '/checklist-registration/:id',
      exact: true,
      component: ChecklistRegistrationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização do Checklist',
      // path: '/checklist-visualization/:id/:customerProjectId/:disableChange',
      path: '/checklist-visualization/:id',
      exact: true,
      component: ChecklistVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Edição de Checklist',
      // path: '/checklist-editing/:id/:customerProjectId/:disableChange',
      path: '/checklist-editing/:id',
      exact: true,
      component: ChecklistEditingPage,
      isPrivateRoute: true,
    },
    {
      title: 'Tipos de Controles',
      path: '/control-types',
      exact: true,
      component: ControlTypesPage,
      isPrivateRoute: true,
    },
    {
      title: 'Atividades',
      path: '/activities',
      exact: true,
      component: ActivitiesPage,
      isPrivateRoute: true,
    },
    {
      title: 'Perguntas',
      path: '/questions',
      exact: true,
      component: QuestionsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Questionário',
      path: '/questionnaries',
      exact: true,
      component: QuizPage,
      isPrivateRoute: false,
    },
    {
      title: 'Registro do Questionário',
      path: '/questionnaire-registration',
      exact: true,
      component: QuizRegistrationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Edição do Questionário',
      path: '/questionnaire-editing/:id',
      exact: true,
      component: QuizRegistrationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização do Questionário',
      path: '/quiz-visualization/:id',
      exact: true,
      component: QuizVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Vincular Perguntas',
      path: '/quiz-and-questions-bonds',
      exact: true,
      component: QuizAndQuestionsBonds,
      isPrivateRoute: true,
    },
    {
      title: 'Vínculos',
      path: '/quiz-and-questions-review',
      exact: true,
      component: QuizReview,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização da Pergunta',
      path: '/question-visualization/:id',
      exact: true,
      component: QuestionVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Registro da Pergunta',
      path: '/question-registration/:id',
      exact: true,
      component: QuestionRegistrationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Joias da Coroa',
      path: '/crown-jewels',
      exact: true,
      component: CrownJewelsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Projeto Cliente',
      path: '/customer-project',
      exact: true,
      component: CustomerProject,
      isPrivateRoute: true,
    },
    {
      title: 'Projeto Cliente Vínculos',
      path: '/customer-project-registrations/:id/:project_type_id',
      exact: true,
      component: CustomerProjectStep2Registration,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização do Projeto Cliente',
      path: '/customer-project-detail',
      exact: true,
      component: CustomerProjectDetail,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização do Projeto Cliente',
      path: '/analyst-questionnaire-answer/:projectId/:questionnaireId/:projectName/:disabledChange',
      exact: true,
      component: QuestionnaireAnalystAnswer,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização do questionário no Projeto Cliente',
      path: '/project-questionnaire-visualization/:projectId/:customerProjectQuestionnaireId/:projectName/:disabledChange',
      exact: true,
      component: CustomerProjectQuestionnaireVisualizationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização da atividade dentro de Checklist',
      path: '/customer-project-checklist-activity-detail/:id/:disableChange',
      exact: true,
      component: ChecklistActivityDetail,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização da Pergunta dentro de Checklist',
      path: '/customer-project-checklist-question-detail/:customerProjectId/:answerId/:disableChange',
      exact: true,
      component: ChecklistQuestionDetail,
      isPrivateRoute: true,
    },
    {
      title: 'Incluir Checklist ao projeto cliente',
      path: '/customer-project-checklist-addition/:id',
      exact: true,
      component: ChecklistAddition,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização do questionário no Projeto Cliente',
      path: '/answer-registration/:questionnaireId/:answerId/:customerProjectId/:projectName/:disableChange',
      exact: true,
      component: QuestionnaireAnswerEditRegistrations,
      isPrivateRoute: true,
    },
    {
      title: 'Incluir questionário ao projeto cliente',
      path: '/questionnaire-include-customer-project/:id',
      exact: true,
      component: QuestionnaireAddQuestionnaire,
      isPrivateRoute: true,
    },
    {
      title: 'Enviar resposta de atividade vículos',
      path: '/checklist-answer-activity-registrations/:customerProjectid/:activityId/:disableChange',
      exact: true,
      component: SendActivityStep2Registrations,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização de riscos de projeto cliente',
      path: '/customer-project-risk-visualization/:customerProjectId/:riskId/:disableChange',
      exact: true,
      component: CustomerProjectRiskVisualization,
      isPrivateRoute: true,
    },
    {
      title: 'Edição de vínculos do risco de projeto cliente',
      path: '/customer-project-risk-edition-registrations/:customerProjectId/:riskId/:disableChange',
      exact: true,
      component: CustomerProjectRiskEditionRegistrations,
      isPrivateRoute: true,
    },
    {
      title: 'Editar vínculos de resposta de perguntas em Checklist',
      path: '/checklist-answer-question-registrations/:customerProjectId/:answerId/:disableChange',
      exact: true,
      component: AnswerRegistrations,
      isPrivateRoute: true,
    },
    {
      title: 'Editar recomendações de risco do projeto cliente',
      path: '/customer-project-edition-stages/:customerProjectId/:riskId/:disableChange',
      exact: true,
      component: RecommendationCustomerProjectEdition,
      isPrivateRoute: true,
    },
    {
      title: 'Configuração de Projetos',
      path: '/project-types',
      exact: true,
      component: ProjectSettingsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Configuração de Projetos',
      path: '/project-types-registration/:id/',
      exact: true,
      component: ProjectSettingsStep2Registration,
      isPrivateRoute: true,
    },
    {
      title: 'Configuração de Projetos',
      path: '/project-types-edition/:id/',
      exact: true,
      component: ProjectSettingsEdit,
      isPrivateRoute: true,
    },
    {
      title: 'Configuração de Projetos',
      path: '/project-types-vizualization/:id/',
      exact: true,
      component: ProjectSettingsVisualization,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização de ameaças de projeto cliente',
      path: '/threats-customer-project/:threatId/detail/:customerProjectId/',
      exact: true,
      component: CustomerProjectThreatVisualization,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização de controles de projeto cliente',
      path: '/control-customer-project/:controlId/detail',
      exact: true,
      component: CustomerProjectControlVisualization,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização de recomendações de controle',
      path: '/control-risk-customer-project/:customerProjectId/:controlId/detail/',
      exact: true,
      component: ControlRecommendationsVisualization,
      isPrivateRoute: true,
    },
    {
      title: 'Edição de resposta do questionário.',
      path: '/edit-questionnaire/:projectId/:questionnaireId/:projectName/:disableChange',
      exact: true,
      component: QuestionnaireEditAnswer,
      isPrivateRoute: true,
    },
    {
      title: 'Visuzalização do formuário NIST no Projeto Cliente',
      path: '/project-questionnaire-nist/:customerProjectId/:choice',
      exact: true,
      component: FormQuestionnaireNIST,
      isPrivateRoute: true,
    },
    {
      title: 'Média de mercado',
      path: '/market-average',
      exact: true,
      component: MarketAveragePage,
      isPrivateRoute: true,
    },
    {
      title: 'Detalhes da média de mercado',
      path: '/market-average-details/:id/:type',
      exact: true,
      component: MarketAverageDetails,
      isPrivateRoute: true,
    },
    {
      title: 'Frameworks',
      path: '/frameworks',
      exact: true,
      component: FrameworksPage,
      isPrivateRoute: true,
    },
    {
      title: 'Standards',
      path: '/standards',
      exact: true,
      component: StandardsPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização do Framework',
      path: '/frameworks/:id/detail/',
      exact: true,
      component: FrameworksVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização das Normas',
      path: '/standards/:id/detail/',
      exact: true,
      component: StandardsVisualizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização das normas dos Framework',
      path: '/csf-core-ref/:id/:frameworkId/detail/',
      exact: true,
      component: FrameworkCSFCoreStandardsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização dos controles dos Framework',
      path: '/csf-core-ref/:id/:frameworkId/controls-detail/',
      exact: true,
      component: FrameworkCSFCoreControlsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização dos projetos para anonimização',
      path: '/projects-for-anonymization',
      exact: true,
      component: ProjectsForAnonymizationPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização do detail dos projetos para anonimização',
      path: '/anonymization-requests/:id/detail/',
      exact: true,
      component: AnonymizationDetailsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização de logs da aplicação',
      path: '/logs',
      exact: true,
      component: ApplicationLogsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização do detail de logs do projeto',
      path: '/auditlogs/:id/:type/detail/',
      exact: true,
      component: ApplicationLogsDetailsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização do Soluções do projeto',
      path: '/solutions',
      exact: true,
      component: SolutionsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Víncular soluções a controles',
      path: '/solutions/:id/controls/',
      exact: true,
      component: SolutionControlsLinkPage,
      isPrivateRoute: false,
    },
    {
      title: 'Visualização de soluções de controle',
      path: '/solutions-project/:id/:customerProjectId/detail/',
      exact: true,
      component: ControlSolutionsVisualizationPage,
      isPrivateRoute: true,
    },
    {
      title: 'Visualização dos itens dos Framework',
      path: '/csf-core-ref/:id/:frameworkId/itens-detail/',
      exact: true,
      component: FrameworkCSFCoreItensPage,
      isPrivateRoute: false,
    },
    {
      title: 'Perfis',
      path: '/user-profiles',
      exact: true,
      component: UserProfilesPage,
      isPrivateRoute: false,
    },
    {
      title: 'Perfis',
      path: '/user-profile-detail/:profileId',
      exact: true,
      component: UserProfileDetailPage,
      isPrivateRoute: false,
    },
    {
      title: 'Grupos',
      path: '/groups/',
      exact: true,
      component: GroupsPage,
      isPrivateRoute: false,
    },
    {
      title: 'Grupos',
      path: '/group-detail/:groupId',
      exact: true,
      component: GroupDetailPage,
      isPrivateRoute: false,
    },
  ];

  return (
    <Suspense fallback={<Loading />}>
      <CustomSwitch>
        {routesData.map(route => (
          <CustomRoute key={route.title} {...route} />
        ))}
        <Route path='*' exact component={PageNotFound404Page} />
      </CustomSwitch>
    </Suspense>
  );
}
