import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  SolutionsState,
  SolutionsActions,
} from './types';

const {
  EDIT_SOLUTION_REQUEST,
  EDIT_SOLUTION_SUCCESS,
  EDIT_SOLUTION_FAILURE,
  ADD_NEW_SOLUTION_REQUEST,
  ADD_NEW_SOLUTION_SUCCESS,
  ADD_NEW_SOLUTION_FAILURE,
  GET_ALL_SOLUTIONS_REQUEST,
  GET_ALL_SOLUTIONS_SUCCESS,
  GET_ALL_SOLUTIONS_FAILURE,
  GET_SOLUCTION_DETAIL_REQUEST,
  GET_SOLUCTION_DETAIL_SUCCESS,
  GET_SOLUCTION_DETAIL_FAILURE,
  GET_SOLUTIONS_PROJECT_REQUEST,
  GET_SOLUTIONS_PROJECT_SUCCESS,
  GET_SOLUTIONS_PROJECT_FAILURE,
  LINK_OR_UNLINK_CONTROLS_REQUEST,
  LINK_OR_UNLINK_CONTROLS_SUCCESS,
  LINK_OR_UNLINK_CONTROLS_FAILURE,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS,
  ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE,
  EDIT_CONTROLS_SOLUTION_RANKING_REQUEST,
  EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS,
  EDIT_CONTROLS_SOLUTION_RANKING_FAILURE,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS,
  ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS,
  ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS,
  GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS,
  GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE,
  GET_FILTERED_SOLUTIONS_SUCCESS,
  GET_FILTERED_SOLUTIONS_FAILURE
} = SolutionsActions;

const initialState: SolutionsState = {
  allSolutions: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  solutionDetails: {
    id: '',
    name: '',
    is_active: false,
    controls: [],
  },
  solutionsProjectDetail: {
    solutions: [],
  },
  solutionControlsDetail: {
    id: '',
    name: '',
    is_active: false,
    position: 0,
    controls: [],
  }
};

export const solutionsReducer: Reducer<SolutionsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_SOLUTIONS_REQUEST:
        break;

      case GET_ALL_SOLUTIONS_SUCCESS:
      case GET_FILTERED_SOLUTIONS_SUCCESS: {
        const { solutions } = action.payload;
        draft.allSolutions = solutions;
        break;
      }

      case GET_ALL_SOLUTIONS_FAILURE:
      case GET_FILTERED_SOLUTIONS_FAILURE: {
        draft.allSolutions = {
          count: null,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      case ADD_NEW_SOLUTION_REQUEST:
      case ADD_NEW_SOLUTION_SUCCESS:
      case ADD_NEW_SOLUTION_FAILURE:
        break;

      case EDIT_SOLUTION_REQUEST:
      case EDIT_SOLUTION_SUCCESS:
      case EDIT_SOLUTION_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_SOLUCTIONS_REQUEST:
      case ACTIVE_OR_INACTIVE_SOLUCTIONS_SUCCESS:
      case ACTIVE_OR_INACTIVE_SOLUCTIONS_FAILURE:
        break;

      case GET_SOLUCTION_DETAIL_REQUEST:
        break;

      case GET_SOLUCTION_DETAIL_SUCCESS: {
        const { solutionDetails } = action.payload;
        draft.solutionDetails = solutionDetails;
        break;
      }

      case GET_SOLUCTION_DETAIL_FAILURE: {
        draft.solutionDetails = {
          id: '',
          name: '',
          is_active: false,
          controls: [],
        };
        break;
      }

      case ADD_AND_REMOVE_SOLUTION_CONTROLS_REQUEST:
      case ADD_AND_REMOVE_SOLUTION_CONTROLS_SUCCESS:
      case ADD_AND_REMOVE_SOLUTION_CONTROLS_FAILURE:
        break;

      case GET_SOLUTIONS_PROJECT_REQUEST:
        break;

      case GET_SOLUTIONS_PROJECT_SUCCESS: {
        const { solutionsProjectDetail } = action.payload;
        draft.solutionsProjectDetail = solutionsProjectDetail;
        break;
      }

      case GET_SOLUTIONS_PROJECT_FAILURE: {
        draft.solutionsProjectDetail = {
          solutions: [],
        };
        break;
      }

      case GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_REQUEST:
      case GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_SUCCESS:
      case GENERATE_CONTROL_SOLUTION_CUSTOMER_PROJECT_FAILURE:
        break;

      case GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_REQUEST:
        break;

      case GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_SUCCESS: {
        const { solutionControlsDetail } = action.payload;
        draft.solutionControlsDetail = solutionControlsDetail;
        break;
      }

      case GET_CONTROL_SOLUTIONS_PROJECT_DETAIL_FAILURE: {
        draft.solutionControlsDetail = {
          id: '',
          name: '',
          is_active: false,
          position: 0,
          controls: [],
        };
        break;
      }

      case LINK_OR_UNLINK_CONTROLS_REQUEST:
      case LINK_OR_UNLINK_CONTROLS_SUCCESS:
      case LINK_OR_UNLINK_CONTROLS_FAILURE:
        break;

      case ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_REQUEST:
      case ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_SUCCESS:
      case ACTIVE_OR_INACTIVE_CONTROL_SOLUCTION_FAILURE:
        break;

      case EDIT_CONTROLS_SOLUTION_RANKING_REQUEST:
      case EDIT_CONTROLS_SOLUTION_RANKING_SUCCESS:
      case EDIT_CONTROLS_SOLUTION_RANKING_FAILURE:
        break;

      default:
    }
  });
};
